import React from 'react'
import { AppContext } from '../context/AppContext'

export const withContext = Component => {
  return props => (
    <AppContext.Consumer>
      {context => <Component {...props} context={context} />}
    </AppContext.Consumer>
  )
}

// export function useFetch(url) {
//   const [data, setData] = useState([])
//   const [loading, setLoading] = useState(true)
//   async function fetchUrl() {
//     const response = await fetch(url)
//     const json = await response.json()
//     setData(json)
//     setLoading(false)
//   }
//   useEffect(() => {
//     fetchUrl()
//   }, [])
//   return [data, loading]
// }

export function typeChecker(value) {
  if (value && typeof value === 'object' && value.constructor === Array)
    return 'array'
  if (value && typeof value === 'object' && value.constructor === Object)
    return 'object'
  if (typeof value === 'string' || value instanceof String) return 'string'
  if (typeof value === 'number' && isFinite(value)) return 'number'
}
