import React from 'react'
import { Text, Flex } from '../styles/components'
import { Spinner } from 'reactstrap'
import { XCircle } from 'react-feather'
import { yellow } from '../styles/styles'

const FetchHandler = ({
  loading,
  error,
  content,
  refetch,
  size = 'sm',
  margin = '10vh 0',
}) => {
  if (loading) {
    return (
      <Flex justifyContent="center" column margin={margin}>
        <Spinner size={size} margin="0 auto" />
      </Flex>
    )
  }
  if (error) {
    return (
      <Flex justifyContent="center" column margin={margin}>
        <XCircle size={60} />
        <Text style={{ textAlign: 'center' }} margin="10px 0 0">
          Gagal memuat data,{' '}
          <span style={{ color: yellow, cursor: 'pointer' }} onClick={refetch}>
            coba lagi
          </span>
        </Text>
      </Flex>
    )
  }
  return content
}

export default FetchHandler
